import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"

export default function PostTemplate({ data }) {
  console.log(data)

  return (
    <Layout isInvestor>
      <Seo title="Articles" />

      {data.wpMfnnewsTag?.mfnItems?.nodes.map(post => {
        return <div key={post.id}>{post.title}</div>
      })}
    </Layout>
  )
}

export const query = graphql`
  query ($id: String = "") {
    wpMfnnewsTag(id: { eq: $id }) {
      slug
      uri
      name
      id
      mfnItems {
        nodes {
          title
          slug
          uri
          id
        }
      }
    }
  }
`
